<template>
	<div class="dashboard-home">
		<h2 class="content-block">Dashboard</h2>
		<div class="content-block">
			<DxLoadPanel v-model:visible="isLoading" />
			<div class="dx-card dashboard-card">
				<div class="header">At a glance</div>
				<DxBox v-if="userStatus.display == 'staff'" :element-attr="{ class: 'staffHint' }">
					<DxItem :ratio="1">
						<span>You are logged in as a staff member.</span>
					</DxItem>
				</DxBox>
			</div>
		</div>
	</div>
</template>

<script setup>
import auth from "../auth";
import { useRouter } from 'vue-router';
import { onMounted, inject, ref } from 'vue'
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxBox, DxItem } from 'devextreme-vue/box';
import notify from 'devextreme/ui/notify';
const $http = inject('$http');
const isLoading = ref(true);
const userStatus = ref({});
const router = useRouter();

// Fetch application/survey status for current user to check if we should auto-redirect.
const fetchUserStatus = $http.get('/user/me');

onMounted(() => {
	fetchUserStatus.then((response) => {
		isLoading.value = false;
		userStatus.value = response.data;
	}).catch(err => {
		isLoading.value = false;
		if (err.response.status == 401) {
			auth.logOut();
			router.push({
				path: "/login-form",
				query: { redirect: "/home" }
			});
			return;
		}
		console.error([err]);
		notify({ message: "Failed to communicate with server!", position: 'center', shading: true, shadingColor: '#cccc' }, "error", 3000);
		userStatus.value = {};
	});
});
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
.dashboard-card {
	margin: 1em 0;
	padding: 20px;

	.header {
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 1em;
	}

	.dx-box {
		margin-bottom: 1em;
		border-left: 15px solid $base-accent;
		border-radius: 10px;
		background: linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(221,221,221,0) calc(max(50%, 550px)));
		.dx-icon {
			vertical-align: top;
			line-height: 17px;
			padding-right: 5px;
			color: $base-accent;
		}
		.dx-item {
			padding: 20px 10px;
		}
		&.success {
			border-left-color: green;
			.dx-icon {
				color: green;
			}
		}
		&.staffHint {
			border-left-color: $base-text-color;
		}
	}
}
.app.screen-x-small .dashboard-card .dx-box .dx-item {
	padding: 10px 10px;
}
</style>