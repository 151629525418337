import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import DemoProfile from "./views/demo-profile";
import DemoProfileList from "./views/demo-profile-list";
import DemoCompany from "./views/demo-company";
import ManageEmployees from "./views/manage-employees";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
	return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
	routes: [
		{
			path: "/home",
			name: "home",
			meta: {
				requiresAuth: true,
				layout: defaultLayout
			},
			component: Home
		},
		{
			path: "/profile",
			name: "profile",
			meta: {
				requiresAuth: true,
				layout: defaultLayout
			},
			component: Profile
		},
		{
			path: "/demo-profile",
			name: "demo_profile",
			meta: {
				requiresAuth: true,
				layout: defaultLayout,
				backdrop: 'profile-backdrop'
			},
			component: DemoProfile
		},
		{
			path: "/demo-profile-list",
			name: "demo_profile_list",
			meta: {
				requiresAuth: true,
				layout: defaultLayout,
				backdrop: 'profile-backdrop'
			},
			component: DemoProfileList
		},
		{
			path: "/demo-company",
			name: "demo_company",
			meta: {
				requiresAuth: true,
				layout: defaultLayout,
				backdrop: 'profile-backdrop'
			},
			component: DemoCompany
		},
		{
			path: "/manage",
			redirect: "/manage/employees"
		},
		{
			path: "/manage/employees",
			name: "manage_employees",
			meta: {
				requiresAuth: true,
				layout: defaultLayout
			},
			component: ManageEmployees
		},
		{
			path: "/tasks",
			name: "tasks",
			meta: {
				requiresAuth: true,
				layout: defaultLayout
			},
			component: Tasks
		},
		{
			path: "/login-form",
			name: "login-form",
			meta: {
				requiresAuth: false,
				layout: simpleLayout,
				title: "Sign in / Register",
			},
			component: loadView("login-form")
		},
		{
			path: "/reset-password",
			name: "reset-password",
			meta: {
				requiresAuth: false,
				layout: simpleLayout,
				title: "Reset Password",
				description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
			},
			component: loadView("reset-password-form")
		},
		{
			path: "/change-password/:recoveryCode",
			name: "change-password",
			meta: {
				requiresAuth: false,
				layout: simpleLayout,
				title: "Change Password"
			},
			component: loadView("change-password-form")
		},
		{
			path: "/",
			redirect: "/home"
		},
		{
			path: "/recovery",
			redirect: "/home"
		},
		{
			path: "/:pathMatch(.*)*",
			redirect: "/home"
		}
	],
	history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {

	if (to.name === "login-form" && auth.loggedIn()) {
		next({ name: "home" });
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!auth.loggedIn()) {
			next({
				name: "login-form",
				query: { redirect: to.fullPath }
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
