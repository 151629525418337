<template>
	<div>
		<h2 class="content-block">Profiles Management</h2>

		<dx-data-grid style="width:100%;"
			class="dx-card wide-card"
			height="80vh"
			:data-source="dataSourceConfig"
			:focused-row-index="0"
			:show-borders="false"
			:focused-row-enabled="true"
			:column-auto-width="false"
			:column-hiding-enabled="true"
			:allowColumnResizing="true" columnResizingMode="widget"
			@exporting="onExporting"
		>
			<dx-state-storing :enabled="false" type="localStorage" storage-key="manage-employees" />
			<dx-column-chooser :enabled="true" mode="select" :searchEnabled="true" height="400px">
				<dx-position my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button" />
			</dx-column-chooser>
			<dx-paging :page-size="50" />
			<dx-pager :show-page-size-selector="true" :show-info="true" :allowedPageSizes="[ 20, 50, 100, 200, 'all' ]" />
			<dx-filter-row :visible="true" />
			<dx-header-filter :visible="true" />
			<dx-search-panel :visible="true" width="300px" />
			<dx-toolbar>
				<dx-item location="before" name="groupBySwitcher">
					<dx-button icon="hierarchy" hint="Group by school"
						styling-mode="contained" :type="groupBySchool ? 'success' : 'default'"
						@click="groupBySwitcher"
					/>
				</dx-item>
				<dx-item location="before" name="groupPanel" />
				<dx-item location="before" name="searchPanel" />
				<dx-item location="after" name="refreshButton">
					<dx-button icon="refresh" hint="Refresh data" @click="refreshButton" />
				</dx-item>
				<dx-item location="after" name="exportButton" />
				<dx-item location="after" name="columnChooserButton" />
			</dx-toolbar>
			<dx-scrolling :useNative="true" mode="infinite" row-rendering-mode="virtual" />
			<dx-export :enabled="true" :formats="['xlsx', 'pdf']" />

			<dx-column data-field="user--id" data-type="number" caption="UserID" :width="120" :allow-header-filtering="false" sortOrder="asc" />
			<dx-column data-field="user--name" caption="UserName" :width="200" :allow-header-filtering="false" />
			<dx-column data-field="user--email" caption="UserEmail" :width="200" :allow-header-filtering="false" />

			<dx-column data-field="application--id" data-type="number" caption="AppID" :width="90" :allow-header-filtering="false" />
			<dx-column data-field="application--updated_at" data-type="datetime" caption="Last changed" :width="160" :allow-header-filtering="false" />
			<dx-column data-field="application--branch" caption="Branch" :width="150" :allow-header-filtering="true" :group-index="groupBySchool ? 1 : undefined" />
			<dx-column data-field="application--school" caption="School" :width="150" :allow-header-filtering="true" :group-index="groupBySchool ? 1 : undefined" />
			<dx-column data-field="application--course" caption="Course" :width="100" :allow-header-filtering="true" />
			<dx-column caption="Name">
				<dx-column data-field="application--first_name" caption="First" :width="100" :allow-header-filtering="false" />
				<dx-column data-field="application--middle_name" caption="Middle" :width="100" :allow-header-filtering="false" />
				<dx-column data-field="application--last_name" caption="Last" :width="100" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="application--mobile" caption="Mobile" :width="130" :allow-header-filtering="false" />
			<dx-column data-field="application--email" caption="Email" :width="190" :allow-header-filtering="false" />
			<dx-column data-field="application--facebook" caption="Facebook Messenger" :width="190" cell-template="facebookLinkTemplate" :allow-header-filtering="false" />
			<dx-column data-field="application--address" caption="Address" :width="250" :allow-header-filtering="false" />
			<dx-column caption="Emergency Contact">
				<dx-column data-field="application--emergency_phone" caption="E. Phone" :width="130" :allow-header-filtering="false" />
				<dx-column data-field="application--emergency_name" caption="E. Name" :width="130" :allow-header-filtering="false" />
				<dx-column data-field="application--emergency_relationship" caption="Relation" :width="130" :allow-header-filtering="true" />
				<dx-column data-field="application--emergency_address" caption="E. Address" :width="130" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="application--created_at" data-type="datetime" caption="Submitted at" :width="160" :allow-header-filtering="false" />
			
			<dx-column data-field="survey--id" data-type="number" caption="SvyID" :width="90" :allow-header-filtering="false" />
			<dx-column data-field="survey--updated_at" data-type="datetime" caption="Last changed" :width="160" :allow-header-filtering="false" />
			<dx-column caption="Preferred Work Environment">
				<dx-column data-field="survey--job_prefer_employer_kind" data-type="string" caption="Kind" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--job_prefer_employer_site" data-type="string" caption="Site" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--job_prefer_employer_region" data-type="string" caption="Region" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--job_prefer_employer_country" data-type="string" caption="Country" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--job_prefer_employer_position" data-type="string" caption="Position" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--job_prefer_employer_position_desc" data-type="string" caption="Description" :width="200" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="survey--current_job_status" data-type="string" caption="Has Job?" :width="100" :allow-header-filtering="false" />
			<dx-column caption="Current Employer">
				<dx-column data-field="survey--employer_site" data-type="string" caption="Site" :width="120" :allow-header-filtering="true" />
				<dx-column data-field="survey--employer_region" data-type="string" caption="Region" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--employer_country" data-type="string" caption="Country" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--employer_kind" data-type="string" caption="Kind" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--employer_kind_specifics" data-type="string" caption="Kind Specifics" :width="140" :allow-header-filtering="false" />
			</dx-column>
			<dx-column caption="Current Job">
				<dx-column data-field="survey--job_position" data-type="string" caption="Position" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--job_position_area" data-type="string" caption="Description" :width="140" :allow-header-filtering="false" />
				<dx-column data-field="survey--job_duration_ends" data-type="date" caption="Ends At" :width="140" :allow-header-filtering="false" />
				<dx-column data-field="survey--job_intent" data-type="string" caption="Intent after" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--job_intent_same_place" data-type="string" caption="Stay same place? " :width="150" :allow-header-filtering="false" />
			</dx-column>
			<dx-column caption="Job Applications">
				<dx-column data-field="survey--employment_plans" data-type="string" caption="Plans" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="survey--employment_applied_to_regions" data-type="string" caption="Regions applied" :width="170" :allow-header-filtering="false" />
				<dx-column data-field="survey--employment_applied_to_countries" data-type="string" caption="Countries applied" :width="170" :allow-header-filtering="false" />
				<dx-column data-field="survey--employment_no_job_reason" data-type="string" caption="Reason for not applying" :width="170" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="survey--allow_profile_matching" data-type="string" caption="Profile Matching" :width="140" :allow-header-filtering="false" />
			<dx-column data-field="survey--created_at" data-type="datetime" caption="Submitted at" :width="160" :allow-header-filtering="false" />

		</dx-data-grid>
	</div>
</template>

<script>
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid, {
	DxColumn,
	DxFilterRow,
	DxPager,
	DxPaging,
	DxColumnChooser,
	DxPosition,
	DxStateStoring,
	DxToolbar,
	DxItem,
	DxSearchPanel,
	DxScrolling,
	DxHeaderFilter,
	DxExport
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import { inject, ref } from "vue";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

export default {
	setup() {
		const $http = inject('$http');
		const dataSourceConfig = new CustomStore({
			key: 'user--id',
			loadMode: 'raw',
			remoteOperations: false,
			load: () => {
				return $http.get('/demoprofiles').then(response => response.data);
			}
		});
		const onExporting = (e) => {
			let dateCode = (new Date()).toISOString().substring(0, 10);
			if (e.format == 'xlsx') {
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Main sheet');
				exportDataGrid({
					component: e.component,
					worksheet: worksheet,
					customizeCell: function(options) {
						options.excelCell.font = { name: 'Arial', size: 12 };
						options.excelCell.alignment = { horizontal: 'left' };
					} 
				}).then(function() {
					workbook.xlsx.writeBuffer()
						.then(function(buffer) {
							saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Employees-' + dateCode + '.xlsx');
						});
				});
			} else if (e.format == 'pdf') {
				const doc = new jsPDF();
				exportDataGridToPdf({
					jsPDFDocument: doc,
					component: e.component
				}).then(() => {
					doc.save('Employees-' + dateCode + '.pdf');
				});
			}
		};

		// Allow toggle button for automatically switching to group-by school column - don't allow other grouping selections.
		const groupBySchool = ref(false);
		const groupBySwitcher = () => {
			groupBySchool.value = !groupBySchool.value;
		}

		const refreshButton = () => {};

		return {
			dataSourceConfig,
			onExporting,
			groupBySchool, groupBySwitcher, refreshButton,
		};
	},
	components: {
		DxDataGrid,
		DxColumn,
		DxFilterRow,
		DxPager,
		DxPaging,
		DxColumnChooser,
		DxPosition,
		DxStateStoring,
		DxToolbar,
		DxItem,
		DxButton,
		DxSearchPanel,
		DxScrolling,
		DxHeaderFilter,
		DxExport
	}
};
</script>
