<template>
	<div class="company-profile">
		<DxLoadPanel v-model:visible="isLoading" />
		<DxPopover target="#eyebrow-globe" show-event="dxhoverstart" hide-event="dxhoverend">
			<div style="max-width:180px;text-align:center;">{{ companyProfile.name }} is an international company with locations in multiple countries.</div>
		</DxPopover>
		<DxPopover target="#eyebrow-runner" show-event="dxhoverstart" hide-event="dxhoverend">
			<div style="max-width:180px;text-align:center;">{{ companyProfile.name }} are willing to negotiate for relocation for talent.</div>
		</DxPopover>
		<DxPopover target="#eyebrow-tips" show-event="dxhoverstart" hide-event="dxhoverend">
			<div style="max-width:180px;text-align:center;">{{ companyProfile.name }} has indicated they are interested in sponsoring potential talent.</div>
		</DxPopover>
		<div style="display:flex;">
			<div class="companytitle">{{ companyProfile.name }}</div>
			<div class="titlebars">
				<hr><hr><hr>
				<div id="eyebrow-globe" class="icon-circle"><i class="dx-icon dx-icon-globe"></i></div>
				<div id="eyebrow-runner" class="icon-circle" style="left:100px;"><i class="dx-icon dx-icon-runner"></i></div>
				<div id="eyebrow-tips" class="icon-circle" style="left:150px;"><i class="dx-icon dx-icon-tips"></i></div>
			</div>
		</div>
		<DxBox direction="row" style="margin-left:10px;">
			<DxItem :baseSize="400">
				<DxTileView :data-source="companyImages" direction="horizontal" :baseItemWidth="180" width="380" height="800" >
					<template #item="{ data }">
						<div :class="{ 'dx-tile-image': true, ['sampleimg-' + data.src]: true }" />
					</template>
				</DxTileView>
			</DxItem>
			<DxItem :ratio="1">
				<div class="abstract" style="max-width:1100px;">
					<div style="display:flex;">
						<div style="flex-grow:1;text-align:justify;padding-right:20px;font-style:italic;">{{ companyProfile.abstract }}</div>
						<div><img class="sampleimg-hs" style="width:125px;height:100%;background-position: center;background-size: cover;display: block;max-height:500px;" />
						</div>
					</div>
				</div>
				<div class="map-holder" style="margin: 0 50px;display:flex;flex-direction:row;">
					<div>
						<DxVectorMap :bounds="bounds" width="30vw" height="300px">
							<DxLayer :data-source="worldData" :customize="customizeWorldLayer" />
							<DxControlBar :enabled="false" />
							<DxTooltip :enabled="true" >
								<DxBorder :visible="true"/>
								<DxFont color="#fff"/>
							</DxTooltip>
						</DxVectorMap>
					</div>
					<div style="flex-grow:1;max-width:300px;margin-left:20px;margin-top:10px;">
						<div class="country-tag-holder"><span class="country-tag"><img src="@/assets/flag-uk.png" />United Kingdom</span></div>
						<div class="country-desc-holder">Our UK clinics are highly regarded and have a reputation for friendly care across the entire country.</div>
						<div class="country-tag-holder"><span class="country-tag"><img src="@/assets/flag-aus.png" />Australia</span></div>
						<div class="country-desc-holder">Australia is a prime choice for nurses wanting to broaden their expertise.</div>
						<div class="country-tag-holder"><span class="country-tag"><img src="@/assets/flag-ph.png" />Western Visayas</span></div>
						<div class="country-desc-holder">Our Filipino division would be more than welcoming; feel free to reach out with your skillset!</div>
					</div>
				</div>
				<div>
					<div style="margin: 20px 50px; display: flex; column-gap: 10px;">
						<div>
							<DxBullet color="#cc0515" :start-scale-value="0" :end-scale-value="100" :target="65" targetColor="#0038a8" :value="63">
								<DxSize :width="(isTiny || isSmall) ? 100 : 250" />
								<DxTooltip :customize-tooltip="customizeBulletTooltip"/>
							</DxBullet>
							Without Relocation (63% match)
						</div>
						<div>
							<DxBullet color="#fcd115" :start-scale-value="0" :end-scale-value="100" :target="70" targetColor="#0038a8" :value="91">
								<DxSize :width="(isTiny || isSmall) ? 100 : 250" />
								<DxTooltip :customize-tooltip="customizeBulletTooltip"/>
							</DxBullet>
							With Relocation (91% match)
						</div>
						<div style="display:flex;flex-direction:column;align-items:center;">
							<div style="color:green;font-size:24px;"><i class="dx-icon dx-icon-check" /><i class="dx-icon dx-icon-check" /><i class="dx-icon dx-icon-check" /></div>
							<div>Matches 3 of your 4 criteria</div>
						</div>
					</div>
				</div>
				<div style="max-width:1200px;">
					<div style="margin: 20px 50px; display: flex; flex-direction: column; row-gap: 20px;">
						<div class="job-items-title">
							<span>Open positions</span>
							<span class="job-items-subtitle">(showing 5 of 13 that match your criteria)</span>
						</div>
						<div class="job-item" v-for="position in companyProfile.jobs" :key="position.id">
							<div><b>{{ position.title }}</b>, {{ position.location}}, <u>{{ position.country }}</u>
								<span style="color:#555;margin-left:20px;"><i style="vertical-align:middle;" class="dx-icon dx-icon-money"></i> {{ position.salary }}</span>
								<span v-if="position.skillset" style="color:#003aa8;margin-left:20px;"><i style="vertical-align:middle;" class="dx-icon dx-icon-key"></i> Skillset / CV match</span>
							</div>
							<div><i class="dx-icon dx-icon-chevrondown"></i></div>
						</div>
					</div>
				</div>
				<div>
				</div>
			</DxItem>
		</DxBox>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxBox, DxItem } from 'devextreme-vue/box';
import { sizes } from "../utils/media-query";
import { DxTileView } from 'devextreme-vue/tile-view';
import { DxVectorMap, DxLayer, DxTooltip, DxBorder, DxFont, DxControlBar } from 'devextreme-vue/vector-map';
import DxBullet, { DxSize } from 'devextreme-vue/bullet';
import { DxPopover } from 'devextreme-vue/popover';

import worldData from '../utils/world';
const bounds = [-180, 85, 180, -60];
const countries = {
	Canada: { color: '#B8860B' },
	'United States': { color: '#FFA07A' },
	'United Kingdom': { color: '#FFA07A' },
	Australia: { color: '#D8BFD8' },
	Argentina: { color: '#CCD700' },
	Philippines: { color: '#FFD700' },
};
const customizeWorldLayer = (elements) => {
	elements.forEach((element) => {
		const country = countries[element.attribute('name')];
		if (country) {
			element.applySettings({
				color: country.color,
				hoveredColor: '#e0e000',
				selectedColor: '#008f00',
			});
		}
	});
}

const customizeBulletTooltip = ({ target, value}) => {
	let relocationMatcher = target != 65;
	return {
		text: !relocationMatcher ?
			`${companyProfile.value.name} has a score of ${value}% matched against your criteria if you do not relocate` :
			`${companyProfile.value.name} has a score of ${value}% matched against your criteria if you relocate to Australia`,
	};
}


const isLoading = ref(false);
const isTiny = sizes()["screen-x-small"];
const isSmall = sizes()["screen-small"];
const isMedium = sizes()["screen-medium"];
console.log(isTiny, isSmall, isMedium);

const companyProfile = ref({
	name: "FutureCare Medical Center",
	abstract: "Welcome to FutureCare Medical Center, where innovation meets compassion in healthcare. Our mission is to deliver personalized, cutting-edge medical services that prioritize the well-being of our patients. We believe that quality care should be accessible, advanced, and rooted in empathy. At FutureCare, our highly skilled team of professionals embraces the latest technology to provide effective treatments while maintaining a deep commitment to patient-centered care. Every individual is treated as a unique partner in their healthcare journey, with treatments tailored to meet their specific needs and goals." +
		"\n\n" +
		"At the heart of FutureCare are our core values: innovation, compassion, integrity, and collaboration. We strive to create a welcoming environment where both patients and staff feel respected and supported. Our commitment to continuous learning ensures that we remain at the forefront of medical advancements, while our dedication to transparency and ethical practices builds trust with our community. Join us at FutureCare Medical Center, where we are shaping the future of healthcare — together.",
	jobs: [
		{ id: 1, title: 'NICU Nurse', salary: 'Competitive salary', country: 'United Kingdom', location: "FutureCare Clinic", skillset: true },
		{ id: 2, title: 'General Ward Nurse', salary: 'Competitive salary', country: 'United Kingdom', location: "FutureCare Health Center" },
		{ id: 3, title: 'NICU Nurse', salary: 'Competitive salary', country: 'Australia', location: "FutureCare Services", skillset: true },
		{ id: 4, title: 'OR Nurse', salary: 'Competitive salary', country: 'Australia', location: "FutureCare Services" },
		{ id: 5, title: 'Emergency Room Nurse', salary: 'Competitive salary', country: 'United Kingdom', location: "FutureCare Horizons" },
	],
});

const companyImages = [
	{ src: '5' },
	{ src: '1', heightRatio: 2 },
	{ src: '2', heightRatio: 2 },
	{ src: '3', heightRatio: 2 },
	{ src: '4', heightRatio: 2 },
	{ src: '6' },
	{ src: '7', heightRatio: 2 },
];

</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
.company-profile {
	background: rgb(0,58,168);
	background: linear-gradient(145deg, rgba(0,58,168,1) 0%, rgba(238,238,238,1) 35%);
	min-height: 100vh;
	> .dx-box.dx-box-flex {
		height: 100%;
	}

	.companytitle {
		font-size: 48px;
		padding: 30px;
		color: #ffffff;
		text-shadow: 4px 4px #656464;
		text-transform: uppercase;
		letter-spacing: 1.1px;
	}
	.titlebars {
		flex-grow: 1;
		margin-top: 50px;
		margin-right: 15px;
		hr {
			border: 1px solid #003ba5;
		}
		.icon-circle {
			display: inline-block;
			position: relative;
			top: -38px;
			left: 50px;
			height: 44px;
			width: 44px;
		}
	}
	.icon-circle {
		background: #003aa8;
		border-radius: 100px;
		padding: 10px;
		color: white;
	}

	.abstract {
		margin: 20px 50px;
		padding: 20px;
		border: 1px solid #003aa8;
		border-left: 5px solid #003aa8;
		background: white;
		color :#333;
		line-height: 20px;
		font-size: 14px;
		white-space: pre-line;
	}

	.country-desc-holder {
		font-style: italic;
		padding-bottom: 10px;
	}
	.country-tag-holder {
		margin-bottom: 10px;
	}
	.country-tag {
		display: block;
		text-decoration: underline;
		background: #3bb4eb;
		text-decoration-color: #3bb4eb;
		color: #222;
		border-radius: 100px;
		font-weight: 500;
		img {
			height: 32px;
			vertical-align: middle;
			padding-right: 5px;
			position: relative;
			left: -2px;;
		}
	}

	.job-items-title {
		font-size: 24px;
		text-transform: uppercase;
		border-bottom: 1px solid #ccc;
		color: #555;
		.job-items-subtitle {
			font-size: 14px;
			color: #777;
			margin-left: 10px;
			position:relative;top:-2px;

		}
	}
	.job-item {
		border: 1px solid black;
		border-radius: 10px 0;
		background: #dedede;
		padding: 20px;
		border-left: 5px solid #003aa8;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.dx-tile-image {
		height: 100%;
		width: 100%;
		background-position: center;
		background-size: cover;
		display: block;
	}
	.sampleimg-1 { background-image: url(@/assets/company_demo_1.png); };
	.sampleimg-2 { background-image: url(@/assets/company_demo_2.png); };
	.sampleimg-3 { background-image: url(@/assets/company_demo_3.png); };
	.sampleimg-4 { background-image: url(@/assets/company_demo_4.png); };
	.sampleimg-5 { background-image: url(@/assets/company_demo_5.png); };
	.sampleimg-6 { background-image: url(@/assets/company_demo_6.png); };
	.sampleimg-7 { background-image: url(@/assets/company_demo_7.png); };
	.sampleimg-hs { background-image: url(@/assets/company_demo_headshot.png); };
	
}
</style>