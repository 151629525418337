import auth from "./auth";

export default [
	{
		text: "Dashboard",
		path: "/home",
		icon: "home"
	},
	{
		text: "Demo Profile",
		path: "/demo-profile",
		icon: "card"
	},
	{
		text: "Demo Profile List",
		path: "/demo-profile-list",
		icon: "datafield"
	},
	{
		text: "Demo Company",
		path: "/demo-company",
		icon: "product"
	},
	{
		text: "Management",
		icon: "folder",
		visible: auth.isStaff.value,
		items: [
			{
				text: "Users",
				path: "/manage/employees"
			}
		]
	}
];