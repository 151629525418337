<template>
	<div class="applicant-profile">
		<DxLoadPanel v-model:visible="isLoading" />
		<DxBox direction="row">
			<DxItem :baseSize="250">
				<div style="background:#d2d2d2; height:100%;">
					<div style="height: 250px; padding:20px; margin: 0 auto; ">
						<img src="@/assets/nurse_demo.th.png" class="headshot" />
					</div>
					<div class="first-col">
						<div class="left-actions">
							<DxButton icon="bookmark" text="Bookmark"></DxButton>
							<DxButton icon="comment" text="Contact"></DxButton>
							<DxButton styling-mode="outlined" type="" icon="warning" text="Report"></DxButton>
						</div>
						<div class="left-title">Contact</div>
						<div class="left-data">
							<div class="left-icons">
								<div><i class="dx-icon dx-icon-tel"></i></div>
								<div>+91 <span class="hidden">[hidden]</span></div>
							</div>
							<div class="left-icons">
								<div><i class="dx-icon dx-icon-email"></i></div>
								<div><span class="hidden">[hidden]</span>@gmail.com</div>
							</div>
							<div class="left-icons">
								<div><i class="dx-icon dx-icon-pinmap"></i></div>
								<div>Bacolod, Philippines</div>
							</div>
							<div class="left-icons">
								<div><i class="dx-icon dx-icon-info"></i></div>
								<div>Details are hidden until contact is requested.</div>
							</div>
						</div>
						<div class="left-title">Languages</div>
						<div class="left-data left-bars">
							<div v-for="lang in applicantProfile.languages" :key="lang.id">
								{{ lang.label }} <span style="font-style:italic;">({{ lang.category }})</span>
								<div v-if="lang.certification" style="margin-top:5px;"><i class="dx-icon dx-icon-taskcomplete" style="margin-right:5px;"></i> {{ lang.certification }}</div>
							</div>
						</div>
						<div class="left-title">Skills</div>
						<div class="left-data left-bars">
							<div v-for="skill in applicantProfile.skills" :key="skill.id">{{ skill.label }}</div>
						</div>
						<div class="left-title">Additional</div>
						<div class="left-data">
							<div class="left-icons">
								<div><i class="dx-icon dx-icon-coffee"></i></div>
								<div>Hobbies:<br>Travel, Biking, Photography
								</div>
							</div>
							<div class="left-icons">
								<div><i class="dx-icon dx-icon-like"></i></div>
								<div>Volunteer Blood Drive<br>Philippine Red Cross</div>
							</div>
							<div class="left-icons">
								<div><i class="dx-icon dx-icon-datapie"></i></div>
								<div>Willing to conduct long-form interviews</div>
							</div>
						</div>
					</div>
				</div>
			</DxItem>
			<DxItem :ratio="1">
				<div class="content-block second-col">
					<div class="nametitle">{{ applicantProfile.fullName }}</div>
					<div class="jobtitle">{{ applicantProfile.jobTitle }}</div>
					<div class="subheading">
						<div class="icon-circle"><i class="dx-icon dx-icon-user" /></div>
						<div class="subheading-title">My profile and preferences</div>
						<hr>
					</div>
					<div class="subdata abstract">
						<div class="abstract-left">{{ applicantProfile.abstract }}</div>
						<div class="abstract-right">
							<div>
								<div class="abstract-heading"><span>I would prefer working for:</span></div>
								<span class="abstract-tag">Primary Hospitals</span>, <span class="abstract-tag">Nursing Homes</span>
							</div>
							<div>
								<div class="abstract-heading"><span>I would prefer working in:</span></div>
								<span class="abstract-tag"><img src="@/assets/flag-uk.png" />United Kingdom</span>, <span class="abstract-tag"><img src="@/assets/flag-aus.png" />Australia</span>, <span class="abstract-tag"><img src="@/assets/flag-ph.png" />Western Visayas</span>
							</div>
							<div>
								<div class="abstract-heading"><span>I would prefer to work as:</span></div>
								<span class="abstract-tag">General Ward Nurse</span>, <span class="abstract-tag">NICU Nurse</span>
							</div>
							<div style="border-top:1px solid #ccc; margin-top: 10px;padding-top:10px; display: flex; column-gap: 10px;">
								<div>
									<DxBullet color="#fcd115" :start-scale-value="0" :end-scale-value="100" :target="65" targetColor="#0038a8" :value="81">
										<DxSize :width="150" />
										<DxTooltip :customize-tooltip="customizeBulletTooltip"/>
									</DxBullet>
									Criteria (81% match)
								</div>
								<div>
									<DxBullet color="#fcd115" :start-scale-value="0" :end-scale-value="100" :target="70" targetColor="#0038a8" :value="95">
										<DxSize :width="150" />
										<DxTooltip :customize-tooltip="customizeBulletTooltip"/>
									</DxBullet>
									Preference (95% match)
								</div>
								<div style="display:flex;flex-direction:column;align-items:center;">
									<div><i class="dx-icon dx-icon-globe" /></div>
									<div>Willing to relocate</div>
								</div>
							</div>
						</div>
					</div>
					<div class="subheading">
						<div class="icon-circle"><i class="dx-icon dx-icon-product" /></div>
						<div class="subheading-title">Experience</div>
						<hr>
					</div>
					<div class="subdata">
						<div>
							<div v-for="exp in applicantProfile.experience" :key="exp.id" class="history-table">
								<div class="history-dot"><i :class="'dx-icon dx-icon-' + (exp.marked ? 'isblank' : 'isnotblank')"></i></div>
								<div class="history-left">
									<div v-if="exp.title" class="history-title">{{ exp.title }}</div>
									<div v-if="exp.subtitle" class="history-sub">{{ exp.subtitle }}</div>
									<div v-if="exp.dateStr" class="history-date">{{ exp.dateStr }}</div>
								</div>
								<div class="history-right">
									<div v-if="exp.achieved" class="history-achieved">{{ exp.achieved }}</div>
									<div v-if="exp.description" class="history-desc">{{ exp.description }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="subheading">
						<div class="icon-circle"><i class="dx-icon dx-icon-taskcomplete" /></div>
						<div class="subheading-title">Education and Qualifications</div>
						<hr>
					</div>
					<div class="subdata">
						<div>
							<div v-for="edu in applicantProfile.education" :key="edu.id" class="history-table">
								<div class="history-dot"><i :class="'dx-icon dx-icon-' + (edu.marked ? 'isblank' : 'isnotblank')"></i></div>
								<div class="history-left">
									<div v-if="edu.title" class="history-title">{{ edu.title }}</div>
									<div v-if="edu.subtitle" class="history-sub">{{ edu.subtitle }}</div>
									<div v-if="edu.dateStr" class="history-date">{{ edu.dateStr }}</div>
								</div>
								<div class="history-right">
									<div v-if="edu.achieved" class="history-achieved">{{ edu.achieved }}</div>
									<div v-if="edu.description" class="history-desc">{{ edu.description }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="subheading">
						<div class="icon-circle"><i class="dx-icon dx-icon-comment" /></div>
						<div class="subheading-title">References</div>
						<hr>
					</div>
					<div class="subdata">
						<div class="references">
							{{ applicantProfile.fullName }} has indicated that they have <b>2 references</b> that are available on request.<br>
							<i>For privacy reasons, you must request contact before reference contact details can be provided.</i>
						</div>
					</div>
				</div>
			</DxItem>
		</DxBox>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxBox, DxItem } from 'devextreme-vue/box';
import { sizes } from "../utils/media-query";
import DxBullet, { DxSize, DxTooltip } from 'devextreme-vue/bullet';
import DxButton from 'devextreme-vue/button';

const isLoading = ref(false);
const isTiny = sizes()["screen-x-small"];

const customizeBulletTooltip = ({ target, value}) => {
	let preferenceMatcher = target != 65;
	return {
		text: !preferenceMatcher ?
			`${applicantProfile.value.firstName} has a score of ${value}% matched against your chosen selection or search criteria` :
			`${applicantProfile.value.firstName} has a score of ${value}% estimated based on their provided job preferences`,
	};
}

const applicantProfile = ref({
	firstName: "Jennette",
	fullName: "Jennette Villarosa Claro",
	jobTitle: "Nurse, Midwife",
	abstract: "My dream is to be a Licensed RN, working to help better my community. I would love the ability to see my training put to use to better provide help for the people around me.",
	experience: [
		{ id: 2, title: "Ladies of Charity Nursing Home", subtitle: "Caraga, Philippines", dateStr: "2024-2025", achieved: "Nursing Home Assistant", description: "Worked with other trainees to assist in nursing duties. Performed charting, ECG monitoring, morning care under supervision.", marked: true },
		{ id: 3, title: "Villa Marillac Community Center", subtitle: "Camarines Sur, Philippines", dateStr: "2023", achieved: "Community Support Assistant", description: "University placement as part of NGO program; performed secretarial duties for frontdesk support in aid of nursing outreach program." }
	],
	education: [
		{ id: 7, title: "PNLE (Pending)", subtitle: "", dateStr: "2024/2025", achieved: "Philippine Nursing Licensure Exam", description: "Awaiting examination results.", marked: true },
		{ id: 9, title: "Palawan State University", subtitle: "Bacolod, Philippines", dateStr: "2021-2024", achieved: "Bachelor of Science in Nursing", description: "Achieved QPV/GPA of 3.5 across 4-year program." },
		{ id: 12, title: "Riverside College", subtitle: "Bacolod, Philippines", dateStr: "2020", achieved: "Joint secondary education", description: "" }
	],
	skills: [
		{ id: 1, label: "Patient Education" },
		{ id: 2, label: "Wound dressing" },
		{ id: 3, label: "Medicine administration" },
		{ id: 4, label: "Problem solving" },
	],
	languages: [
		{ id: 1, label: "English", category: 'fluent', certification: 'CPE CEFR C2' },
		{ id: 2, label: "Tagalog", category: 'fluent' },
		{ id: 3, label: "Bisaya", category: 'fluent' },
	],
});
console.log(isTiny);

</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
.profile-backdrop {
	/*background: rgb(0,58,168);
	background: linear-gradient(145deg, rgba(0,58,168,1) 0%, rgba(238,238,238,1) 35%); */
}
.applicant-profile {
	min-height: 100vh;
	> .dx-box.dx-box-flex {
		height: 100%;
	}

	.second-col {
		margin-top: 40px;
		margin-left: 60px;
	}

	.headshot {
		border-radius: 100px;
		width: 200px;
		height: 200px;
		object-fit: cover;
		object-position: top;
	}
	
	.first-col {
		height: 100%;
		background: #03a9f4f0;
		color: #e0e0e0;
		padding: 20px
	}

	.left-actions {
		width: 100%;
		margin-bottom: 15px;
		.dx-button {
			width: 100%;
			margin-bottom: 5px;
		}
	}
	.left-title {
		margin-bottom: 10px;
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 0.5px;
	}
	.left-data {
		margin-bottom: 30px;

		> div {
			padding: 3px;
			margin-bottom: 10px;
		}
		&.left-bars > div {
			border-left: 5px solid #e0e0e0;
			padding: 3px 3px 3px 8px;
			margin-bottom: 10px;
		}
		> div.left-icons {
			display: flex;
			align-items: center;
		}
		.dx-icon {
			vertical-align: middle;
			margin-right: 10px;
		}
		.hidden {
			color: #ccc;
		}
	}

	.nametitle {
		color: #0fabf2;
		font-size: 44px;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	.jobtitle {
		font-size: 32px;
		text-transform: uppercase;
		margin-bottom: 50px;
	}

	.abstract {
		display: flex;
		.abstract-left {
			margin-left: 20px;
			width: 335px;
			padding: 10px 20px;
			font-style: italic;
			border: 2px solid #3bb4eb;
			border-width: 2px 0;
			background: white;
			border-radius: 5px;
			line-height: 24px;
			font-size: 14px;
		}
		.abstract-right {
			margin-left: 30px;
			padding-top: 10px;
			line-height: 24px;
		}
		.abstract-heading {
			width: 180px;
			display: inline-block;
			span {
				background: #03a9f429;
				padding: 2px 5px;
				border-radius: 3px;
			}
		}
		.abstract-tag {
			text-decoration: underline;
			text-decoration-color: #3bb4eb;
			img {
				height: 20px;
				vertical-align: middle;
				padding-right: 2px;
			}
		}
	}

	.subheading {
		display: flex;
		align-items: center;
		column-gap: 15px;
		margin-bottom: 30px;
		.icon-circle {
			background: #3bb4eb;
			border-radius: 100px;
			padding: 10px;
			color: white;
		}
		.subheading-title {
			font-size: 20px;
			text-transform: uppercase;
		}
		hr {
			flex-grow: 1;
			border-color: #3bb4eb;
		}
	}

	.subdata {
		margin-bottom: 30px;
	}

	.references {
		border-left: 5px solid #3bb4eb;
		padding: 10px 10px 10px 40px;
		font-size: 14px;
		line-height: 24px;
		margin-left: 20px;
	}

	.history-table {
		display: flex;
		flex-direction: row;
		column-gap: 25px;

		.history-dot {
			margin-left: 10px;
			color: #3bb4eb;
		}
		.history-left {
			padding: 0 20px;
			margin-bottom: 30px;
			width: 300px;;
			border-right: 1px solid #3bb4eb77;
		}
		.history-right {
			width: 500px;
			padding: 0 20px;
			margin-bottom: 30px;
		}
		.history-title {
			text-transform: uppercase;
			font-size: 18px;
			line-height: 24px;
		}
		.history-sub {
			font-size: 15px;
			color: #777;
			line-height: 24px;
		}
		.history-date {
			font-size: 15px;
			line-height: 24px;
		}
		.history-achieved {
			text-transform: uppercase;
			font-size: 18px;
			line-height: 24px;
		}
		.history-desc {
			font-size: 15px;
			line-height: 24px;
		}
	}

	
}
</style>